<template>
    <div class="family-manage">
        <div class="tip-cell">
            请慎重选择解散家庭组，解散家庭组后不可恢复。
        </div>
        <div class="btn-cell" @click="disbandFamilyFn">
            解散家庭组
        </div>
    </div>
  </template>
  
  <script>
  import { disbandFamily } from '@/api/family'
  import { Button } from 'vant'
  export default {
    name: 'FamilyManage',
    components: {
        'van-button': Button
    },
    methods: {
        disbandFamilyFn() {
            disbandFamily().then(res => {
                this.$toast('解散成功')
                setTimeout(() => {
                    this.$router.push('/')
                }, 1000)
            }).catch(err => {
                this.$toast(err.msg)
            })  
        }
    }
  }
  </script>
  
  <style scoped lang="less">
  .family-manage {
    display: flex;
    flex-direction: column;
    align-items: center;
    .tip-cell {
        padding-top: 0.7rem;
        width: 5.6rem;
        font-size: 0.36rem;
        line-height: 0.5rem;
        color: #666;
    }
    .btn-cell {
        margin-top: 1.9rem;
        width: 5.6rem;
        height: 0.9rem;
        line-height: 0.9rem;
        text-align: center;
        color: #000;
        background-color: rgb(240, 240, 240);
        font-size: 0.32rem;
        font-weight: 500;
        border-radius: 0.45rem;
    }
  }
  </style>
  